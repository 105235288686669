import React, {useState} from "react";
import FaqAccordion from "./faqAccordion";
import {Accordion} from "react-bootstrap";
import RichText from "../basics/rich-text";

export const FaqKeyContext = React.createContext(null);

const FaqList= ({content : faqList}) => {
  const [selectedFaqKey, setSelectFaqKey] = useState(null);

  return (
    <>
    <FaqKeyContext.Provider value={{selectedFaqKey, setSelectFaqKey}}>
      <Accordion>

        {faqList.map( (item,index) =>(
          <div key={item.id}>
            <FaqAccordion eventKey={index} header={item.title}>
              <RichText content={item.text} />
            </FaqAccordion>
          </div>
        ))}
      </Accordion>
    </FaqKeyContext.Provider>
    </>
  );
}

export default FaqList