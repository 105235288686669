import React from 'react';

import SubblockWrapper from "./subblock-wrapper";
import FaqList from "../faq/faqList";


const SubblockFaqList =({content, position, fullwidth}) => {

  return (
    <>
      <SubblockWrapper position={position} fullwidth={fullwidth} colorTheme="dark">
        <FaqList content={content} />
      </SubblockWrapper>
    </>
  );
}

export default SubblockFaqList;
