import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockPageHeader from "../components/common/subblock/subblock-page-header";
import GapBanner from "../components/common/gapBanner";
import SubblockFaqList from "../components/common/subblock/subblock-faq-list";


const Faq = ({ data }) => {
  const header = data.strapiFaq.header
  const faqList = data.strapiFaq.faqlist

  return (
    <>
      <Seo title="FAQ" />
      <Layout>
        <SubblockPageHeader content={header} />
        <GapBanner />
        <SubblockFaqList content={faqList} position="center" fullwidth />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query strapiFaqQuery {
  strapiFaq {
    header {
      ...fragmentHeader
    }
    faqlist {
      ...FragmentFaqList
    }
  }
}
`

export default Faq


