import React, {useContext, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {FaqKeyContext} from "./faqList";
import {ArrowRight, CaretUpFill} from 'react-bootstrap-icons';


const ContextAwareToggle = ({ children, eventKey}) => {
  const [isActive, setActive] = useState(false)

  const {selectedFaqKey, setSelectFaqKey} = useContext(FaqKeyContext);
  const isCurrentEventKey = selectedFaqKey === eventKey;

  const decoratedOnClickOnKeyDown = (e) => {
    if (e.code !== undefined && e.code !== "Enter") return  // abort when pressed Key is not Enter-Key, prenvent open/close by Tab-Key
    decoratedOnClick(e)
  }

  const decoratedOnClick = useAccordionButton(
    eventKey,
    (e) => {
      // callback && callback(eventKey)
      if (isCurrentEventKey && isActive) {
        setSelectFaqKey( null );
        setActive(false);
      } else {
        setSelectFaqKey(eventKey);
        setActive(true);
      }
    },
  );

  const highlightClass = isCurrentEventKey ? " bg-primary " : "";
  const arrowRightClass = isCurrentEventKey ? " text-white " : " text-primary ";

  return (
    <>
      <h5 className={"text-start text-white user-select-none"} >
        <span className={highlightClass+ " text-start cursor-pointer p-1"} onClick={decoratedOnClick} onKeyDown={decoratedOnClickOnKeyDown} role="button" tabIndex="0">
          <ArrowRight className={arrowRightClass+ "me-1 mb-1"}/>
          {children}
        </span>
      </h5>
    </>
  );
}

const FaqAccordion = ({eventKey, header, children}) => {
  return (
    <>
      <ContextAwareToggle eventKey={eventKey}>{header}</ContextAwareToggle>
      <Accordion.Collapse eventKey={eventKey}>
        <>
          {/* faq polygon */}
          <div className="text-start pt-0 pb-0 px-4" style={{marginTop: "-4px", marginBottom: "-9px", width: "70px"}}>
            <CaretUpFill className={"text-white"} />
          </div>

          {/* faq textbox */}
          <div className="bg-white text-secondary text-start py-2 px-4 mb-3" style={{maxWidth: "750px"}}>
            <div>{children}</div>
            <div className={"text-primary me-1 mb-1 mt-2"}>
              {/*<ArrowRight className={"me-2 mb-1"}/>*/}
              {/*<span className={"text-decoration-underline fw-bold cursor-pointer"}>Fragebogen öffnen</span>*/}
            </div>

          </div>
        </>
      </Accordion.Collapse>
    </>
  );
}

export default FaqAccordion