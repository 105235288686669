import React from 'react';

const GapBanner =() => {
  return (
    <>
      <div className="mt-2">
      </div>
    </>
  );
}

export default GapBanner;
